import { useRef, useState } from "react";
import { useDashboard } from "../Dashboard/useDashboard";
import Loading from "../../components/Loading";
import { toast } from "react-toastify";
import clsx from "clsx";
import { twMerge } from "tailwind-merge";

const countryOptions = [
	{ value: "USA", label: "United States of America" },
	{ value: "Canada", label: "Canada" },
];


const INPUT_CLASS =
	"rounded-[10px] px-4 py-3 bg-page border-[1px] border-stroke border-solid outline-none text-font-400 placeholder:text-[85%] placeholder:text-font-200/85";

export default function Account() {

	const currentToast = useRef(null);

	const {
		currentUser: { user, details },
		location: { navigate },
		set: {
			patient: { visible, dismiss, type: encounterType },
		},
	} = useDashboard();

	const [loading, setLoading] = useState(false);

	// const objImageRef = useRef(null);

	const [imgSrc, setImgSrc] = useState(details?.clinic?.logo ?? null);
	const [imgSrcDisabled, setImgSrcDisabled] = useState(false);

	const regis = useRef(null);
	const clinicName = useRef(null);
	const country = useRef(null);
	const address = useRef(null);

	const first = useRef(null);
	const last = useRef(null);

	const imgBlob = useRef(null);

	async function imageChange(evt) {

		const { errorOpts, successOpts } = await import("../../util/toast");

		const file = evt.target.files[0];

		if (imgSrc) {

			imgBlob.current = null;

			if(imgSrc.indexOf("blob") !== -1) {
				URL.revokeObjectURL(imgSrc)
			}

			setImgSrc(null);
		}

		if (file) {

			if (!file.type.startsWith("image/")) {
				currentToast.current = toast("Selected file is not an image", {
					...errorOpts
				});

				return;
			}

			if (file.size > 5 * 1024 * 1024) {

				currentToast.current = toast("Selected image is not below 5 MB. Please reduce the size and try again.", {
					...errorOpts
				});

				return;
			}

			imgBlob.current = new Blob([file], { type: file.type });

			const img = new Image();

			img.onload = () => {

				if (img.width > 512 || img.height > 512) {

					currentToast.current = toast("Image dimensions should not exceed 512x512", {
						...errorOpts
					});

					imgBlob.current = null;

					return;
				}

				setImgSrc(img.src);

			}


			img.onerror = () => {
				currentToast.current = toast("Sorry could not load the image", {
					...errorOpts
				});

				imgBlob.current = null;
				setImgSrc(null);
			}


			img.src = URL.createObjectURL(imgBlob.current)


		}

	}


	async function submitClinic(evt) {

		const submitBtn = evt.currentTarget;

		const { db, storage } = await import("../../util/firebase");

		const { uploadBytes, ref, getDownloadURL } = await import("firebase/storage");

		const { updateDoc, doc } = await import("firebase/firestore");

		const { errorOpts, successOpts, processOpts } = await import("../../util/toast");

		// console.log(imgBlob.current);

		const _country = country.current?.value;
		const _clinicName = clinicName.current?.value;
		const _regis = regis.current?.value;
		const _address = address.current?.value;

		const _first = first.current?.value;
		let _last = last.current?.value;

		let displayName;

		if(typeof _first !== "string" || _first.trim().length === 0) {
			currentToast.current = toast("First Name is mandatory for a valid account", {
				...errorOpts
			});
			return;
		}

		if(typeof _last === "string" && _last.trim().length > 0) {
			displayName = `${_first.trim()} ${_last.trim()}`;
		}

		if(typeof displayName === "string") {
			displayName = displayName.trim();
		}

		if (!imgSrc || !imgBlob.current) {

			// currentToast.current = toast("A logo is required to be selected. Please select an image file of maxmimum 512px x 512px and under 5 MB.", {
			// 	...errorOpts
			// });

			// return;
		}

		if (!_regis || !_regis?.trim()) {
			currentToast.current = toast("A registration number is required.", {
				...errorOpts
			});

			return;
		}

		if (!_clinicName || !_clinicName?.trim()) {
			currentToast.current = toast("A clinic name is required.", {
				...errorOpts
			});

			return;
		}

		if (!_country || !_country?.trim()) {
			currentToast.current = toast("A country needs to be selected.", {
				...errorOpts
			});

			return;
		}

		const logoRef = ref(storage, `users/${user.uid}/clinic/logo.png`);


		for (let x of [first.current, last.current, country.current, regis.current, address.current, clinicName.current, submitBtn]) {
			if ("disabled" in x) {
				console.log(x['disabled']);

				x['disabled'] = true;
			}
		}

		setImgSrcDisabled(true);

		setLoading(true);

		// Done everything is

		// If no issues

		// console.log(``);

		currentToast.current = toast("Please wait while we update the details. This may take a moment.", {
			...processOpts
		})

		let logo = null;

		if (imgBlob.current) {
			const snapshot = await uploadBytes(logoRef, imgBlob.current, {
				contentType: imgBlob.current?.type
			});

			const url = await getDownloadURL(snapshot.ref);

			logo = {
				ref: snapshot.ref.fullPath,
				url,
				contentType: snapshot.metadata.contentType,
			};
		}

		const payload = {
			logo,
			country: _country,
			name: _clinicName,
			reg: _regis,
			address: _address,
		};

		const userRef = doc(db, `users/${user.uid}`);

		await updateDoc(userRef, {
			clinic: payload,
			displayName,
		});

		toast.dismiss(currentToast.current);

		window.location.reload();
	}

	const firstName = details.displayName.split(' ').at(0);
	const lastName = details.displayName.substring(firstName.length);
	
// var lastName = fullName.split(' ').slice(-1).join(' ');

	return (
		<div className="w-full h-full *:rounded-[20px] flex flex-row flex-1 overflow-y-scroll">
			<div className="bg-white flex flex-col w-full px-12">
				<div className={twMerge("w-full !h-full flex flex-col justify-center items-center gap-y-4", clsx({
					hidden: !loading,
				}))}>
					<Loading classes={"w-[4rem] text-primary"} />
				</div>
				<div className={twMerge("w-full h-full flex flex-col gap-4 ", clsx({
					"hidden": loading,
				}))}>

					<div className="w-full flex flex-col gap-6">
						<div className="flex w-full justify-center items-center">
							<h5 className="font-bold">Personal Details</h5>
						</div>
						<div className="flex flex-col lg:flex-row w-full gap-8">

							<div className="flex flex-col gap-2 w-full">
								<label
									className="text-font-200 text-[95%] font-inter"
									htmlFor="first"
								>
									First Name *
								</label>
								<input
									className={twMerge(
										INPUT_CLASS,
									)}
									ref={first}
									defaultValue={firstName}
									name="first"
									id="first"
									placeholder="First Name"
								/>
							</div>
							<div className="flex flex-col gap-2 w-full">
								<label
									className="text-font-200 text-[95%] font-inter"
									htmlFor="last"
								>
									Last Name
								</label>
								<input
									className={twMerge(
										INPUT_CLASS,
									)}
									ref={last}
									defaultValue={lastName}
									name="last"
									id="last"
									placeholder="Last Name"
								/>
							</div>
						</div>
						<hr />
						<div className="flex w-full justify-center items-center -mt-2">
							<h5 className="font-bold">Clinic Details</h5>
						</div>
						<div className="flex flex-col lg:flex-row w-full gap-8 mt-4">

							<div className="flex flex-col gap-2 w-full">
								<label
									className="text-font-200 text-[95%] font-inter"
									htmlFor="clinic"
								>
									Clinic Name *
								</label>
								<input
									className={twMerge(
										INPUT_CLASS,
									)}
									ref={clinicName}
									defaultValue={details?.clinic?.name}
									name="clinic"
									id="clinic"
									placeholder="Clinic Name"
								/>
							</div>
							<div className="flex flex-col gap-2 w-full">
								<label
									className="text-font-200 text-[95%] font-inter"
									htmlFor="regis"
								>
									Registration Number *
								</label>
								<input
									className={twMerge(
										INPUT_CLASS,
									)}
									ref={regis}
									defaultValue={details?.clinic?.reg}
									name="regis"
									id="regis"
									placeholder="Registration Number"
								/>
							</div>
							<div className="flex flex-col gap-2 w-full *:cursor-pointer">
								<label htmlFor="country" className="font-inter text-font-200 text-[90%]">
									Country of practice *
								</label>
								<select
									ref={country}
									className={twMerge(
										INPUT_CLASS,
										"*:font-inter *:bg-secondary read-only:text-font-400 read-only:bg-page",
									)}
									id="country"
								>
									<option value={""} selected={true}>
										Select Country
									</option>
									{
										countryOptions.map((item) => {
											return (
												<option value={item.value} selected={item.value === details?.clinic?.country}>{item.label}</option>
											)
										})
									}

								</select>
							</div>
						</div>

						<div className="flex flex-col w-full">
							{
								imgSrc && (

									<div className="flex flex-row items-center justify-between">
										<img src={imgSrc} width={"48px"} id="logo-image" className="object-contain max-w-[512px] !aspect-square max-h-[512px] h-full" />
										<span role="button" onClick={() => {
											setImgSrc(null);
											imgBlob.current = null
										}} className={twMerge("text-[1.6rem]", clsx({
											"hidden": imgSrcDisabled,
										}))}>&times;</span>
									</div>
								)
							}

							{!imgSrc &&
								(
									<>

										<button className="rounded-[10px] px-4 py-3 gap-4 flex bg-[#F5F5F6] text-black font-normal text-[1rem] items-center" onClick={() => {
											document.querySelector("#logo")?.click()
										}}>
											<svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M14.75 9.75V12.75C14.75 13.1478 14.592 13.5294 14.3107 13.8107C14.0294 14.092 13.6478 14.25 13.25 14.25H2.75C2.35218 14.25 1.97064 14.092 1.68934 13.8107C1.40804 13.5294 1.25 13.1478 1.25 12.75V9.75M11.75 4.5L8 0.75M8 0.75L4.25 4.5M8 0.75V9.75" stroke="#1E1E1E" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
											</svg>
											{imgSrc === null && (<span>Upload Logo</span>)}

										</button>
										<span className="text-gray-600 font-medium font-mono text-[75%]">Image files only. Maximum of 512px x 512px and under 5 MB <b>(Not Required)</b></span>
										<input onChange={imageChange} type="file" id="logo" name="logo" className="hidden" accept="image/png, image/jpeg" />
									</>
								)
							}

						</div>
						<div className="flex flex-row w-full gap-8">
							<div className="flex flex-col gap-2 w-full">
								<label
									className="text-font-200 select-none text-[95%] font-inter"
									htmlFor="address"
								>
									Clinic Address
								</label>
								<textarea ref={address} id="address" name="address" className={twMerge("w-full resize-y", INPUT_CLASS)} placeholder="Enter your full clinic address">{details?.clinic?.address}</textarea>
							</div>
						</div>
						<button onClick={submitClinic} className="btn flex flex-row mt-[0.8rem] mb-4 lg:mb-0 mx-auto bg-accent stroke-none outline-none border-none w-full hover:bg-accent/95 focus:bg-opacity-100 text-white hover:text-font-800 text-[1.2rem] font-inter font-bold transition-all duration-500 ease-in-out group justify-center focus:border-primary focus:border-[1px] focus:border-solid disabled:bg-gray-300 disabled:text-gray-400 disabled:hover:bg-gray-300 disabled:hover:text-gray-400 disabled:cursor-not-allowed">
							<span>Submit</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	)

}

function Arrows({ className = "" }) {

	return (
		<svg width="241" className={twMerge(...className.split(" "))} height="302" viewBox="0 0 241 302" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path opacity="0.22" d="M-57.4741 185.103C-2.00236 231.228 125.661 202.916 143.302 153.555M143.302 153.555L120.994 157.061M143.302 153.555L147.912 182.896" stroke="#fff" stroke-width="6.6" />
			<path opacity="0.22" d="M186.808 0.791016C197.508 72.1361 107.133 166.646 55.7762 156.152M55.7762 156.152L70.3353 138.89M55.7762 156.152L78.4801 175.3" stroke="#fff" stroke-width="6.6" />
		</svg>
	)
}

import { BASE_DATE, ENCOUNTERS_PATTERN, PATIENT_PATTERN } from "../../util/constant";

import { lazy, Suspense, useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import EncounterProvider from "../Encounter/useEncounter";

import DashboardProvider, { useDashboard } from "./useDashboard";

import Loading from "../../components/Loading";

import flatpickr from "flatpickr";

import { twMerge } from "tailwind-merge";
import clsx from "clsx";

import { set, useForm } from "react-hook-form";

import Select from "react-select";
import ThankYou from "../ThankYou";
import Account from "../Account";

const INPUT_CLASS =
	"rounded-[10px] px-4 py-3 bg-page border-[1px] border-stroke border-solid outline-none text-font-400 placeholder:text-[85%] placeholder:text-font-200/85";


const countryOptions = [
	{ value: "USA", label: "United States of America" },
	{ value: "Canada", label: "Canada" },
];

const Encounter = lazy(() => import("../Encounter"));
const Patient = lazy(() => import("./components/Patient"));
const Sidebar = lazy(() => import("./components/Sidebar"));
const Main = lazy(() => import("./components/Main"));
const Patients = lazy(() => import("./components/Patients"));
const Verify = lazy(() => import("../Verify"));

function ParentLoader() {
	return (
		<div className="w-full h-full flex flex-col justify-center items-center gap-y-4">
			<Loading classes={"w-[4rem] text-primary"} />
		</div>
	)
}

export default function Dashboard() {
	return (
		<DashboardProvider>
			<Suspense fallback={<ParentLoader />}>
				<UI />
			</Suspense>
			<ToastContainer />
		</DashboardProvider>
	);
}

function UI() {

	const {
		currentUser: { auth, user, details },
		location: { currentPath },
		set: { title },
	} = useDashboard();


	console.log(currentPath)
	title(currentPath === "/" ? "Dashboard" : "");

	if (ENCOUNTERS_PATTERN.test(currentPath) === true) {
		return (
			<EncounterProvider>
				<Encounter />
			</EncounterProvider>
		);
	}


	if (currentPath === "/thank-you") {
		return (
			<Parent>
				<Sidebar />
				<Main />
				<ThankYou />
			</Parent>
		)
	}


	if (currentPath === "/" && (!details?.clinic)) {
		return (
			<Parent>
				<Clinic />
			</Parent>
		);
	}

	if (currentPath === "/") {
		// title("Dashboard");
		return (
			<Parent>
				<Sidebar />
				<Main />
			</Parent>
		);
	}

	if (currentPath === "/verify") {
		return (
			<Parent>
				<Sidebar />
				<Verify />
			</Parent>
		)
	}

	if (currentPath === "/my-account") {
		return (
			<Parent>
				<Sidebar />
				<Account />
			</Parent>
		)
	}

	// console.log(PATIENT_PATTERN.test(currentPath));

	if (PATIENT_PATTERN.test(currentPath) === true) {
		return (
			<Parent>
				<Sidebar />
				<Patient />
			</Parent>
		);
	}

	if (currentPath.indexOf("patients") !== -1) {
		return (
			<Parent>
				<Sidebar />
				<Patients />
			</Parent>
		);
	}


}

function Arrows({ className = "" }) {

	return (
		<svg width="241" className={twMerge(...className.split(" "))} height="302" viewBox="0 0 241 302" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path opacity="0.22" d="M-57.4741 185.103C-2.00236 231.228 125.661 202.916 143.302 153.555M143.302 153.555L120.994 157.061M143.302 153.555L147.912 182.896" stroke="#fff" stroke-width="6.6" />
			<path opacity="0.22" d="M186.808 0.791016C197.508 72.1361 107.133 166.646 55.7762 156.152M55.7762 156.152L70.3353 138.89M55.7762 156.152L78.4801 175.3" stroke="#fff" stroke-width="6.6" />
		</svg>
	)
}


function Clinic() {

	const currentToast = useRef(null);

	const {
		currentUser: { user, details },
		location: { navigate },
		set: {
			patient: { visible, dismiss, type: encounterType },
		},
	} = useDashboard();

	const [loading, setLoading] = useState(false);

	// const objImageRef = useRef(null);

	const [imgSrc, setImgSrc] = useState(null);
	const [imgSrcDisabled, setImgSrcDisabled] = useState(false);

	const regis = useRef(null);
	const clinicName = useRef(null);
	const country = useRef(null);
	const address = useRef(null);

	const imgBlob = useRef(null);

	async function imageChange(evt) {

		const { errorOpts, successOpts } = await import("../../util/toast");

		const file = evt.target.files[0];

		if (imgSrc) {

			imgBlob.current = null;

			URL.revokeObjectURL(imgSrc)
			setImgSrc(null);
		}

		if (file) {

			if (!file.type.startsWith("image/")) {
				currentToast.current = toast("Selected file is not an image", {
					...errorOpts
				});

				return;
			}

			if (file.size > 5 * 1024 * 1024) {

				currentToast.current = toast("Selected image is not below 5 MB. Please reduce the size and try again.", {
					...errorOpts
				});

				return;
			}

			imgBlob.current = new Blob([file], { type: file.type });

			const img = new Image();

			img.onload = () => {

				if (img.width > 512 || img.height > 512) {

					currentToast.current = toast("Image dimensions should not exceed 512x512", {
						...errorOpts
					});

					imgBlob.current = null;

					return;
				}

				setImgSrc(img.src);

			}


			img.onerror = () => {
				currentToast.current = toast("Sorry could not load the image", {
					...errorOpts
				});

				imgBlob.current = null;
				setImgSrc(null);
			}


			img.src = URL.createObjectURL(imgBlob.current)


		}

	}


	async function submitClinic(evt) {

		const submitBtn = evt.currentTarget;

		const { db, storage } = await import("../../util/firebase");

		const { uploadBytes, ref, getDownloadURL } = await import("firebase/storage");

		const { updateDoc, doc } = await import("firebase/firestore");

		const { errorOpts, successOpts, processOpts } = await import("../../util/toast");

		// console.log(imgBlob.current);

		const _country = country.current?.value;
		const _clinicName = clinicName.current?.value;
		const _regis = regis.current?.value;
		const _address = address.current?.value;

		if (!imgSrc || !imgBlob.current) {

			// currentToast.current = toast("A logo is required to be selected. Please select an image file of maxmimum 512px x 512px and under 5 MB.", {
			// 	...errorOpts
			// });

			// return;
		}

		if (!_regis || !_regis?.trim()) {
			currentToast.current = toast("A registration number is required.", {
				...errorOpts
			});

			return;
		}

		if (!_clinicName || !_clinicName?.trim()) {
			currentToast.current = toast("A clinic name is required.", {
				...errorOpts
			});

			return;
		}

		if (!_country || !_country?.trim()) {
			currentToast.current = toast("A country needs to be selected.", {
				...errorOpts
			});

			return;
		}

		const logoRef = ref(storage, `users/${user.uid}/clinic/logo.png`);


		for (let x of [country.current, regis.current, address.current, clinicName.current, submitBtn]) {
			if ("disabled" in x) {
				console.log(x['disabled']);

				x['disabled'] = true;
			}
		}

		setImgSrcDisabled(true);

		setLoading(true);

		// Done everything is

		// If no issues

		// console.log(``);

		currentToast.current = toast("Please wait while we set up the clinic. This may take a moment", {
			...processOpts
		})

		let logo = null;

		if (imgBlob.current) {
			const snapshot = await uploadBytes(logoRef, imgBlob.current, {
				contentType: imgBlob.current?.type
			});

			const url = await getDownloadURL(snapshot.ref);

			logo = {
				ref: snapshot.ref.fullPath,
				url,
				contentType: snapshot.metadata.contentType,
			};
		}

		const payload = {
			logo,
			country: _country,
			name: _clinicName,
			reg: _regis,
			address: _address,
		};

		const userRef = doc(db, `users/${user.uid}`);

		await updateDoc(userRef, {
			clinic: payload,
		});

		toast.dismiss(currentToast.current);

	}

	return (
		<div className="w-full h-full *:rounded-[20px] flex flex-row">
			<div className="bg-gradient-90 *:rounded-[20px] flex from-primary from-[0%] to-gradient-end/75 to-[100%] w-full">
				<div className="bg-transparent hidden md:flex items-center  w-[50%] relative">
					<Arrows className="absolute top-0" />
					<Arrows className="absolute bottom-0 rotate-180 right-0" />
					<div className="m-8 flex flex-col gap-4">
						<h4 className="text-white font-inter font-semibold">Welcome, {user.displayName}</h4>
						<p className="text-white font-inter font-normal">Your efficiency helps ensure every patient receives the care they need!</p>
					</div>
				</div>
				<div className="bg-white flex flex-col w-full lg:w-[50%] px-12">
					<div className="flex flex-row justify-between w-full">
						<div className="flex flex-row items-center w-full mt-4">
							<div className="w-[20%]"></div>
							<div className="w-full">
								<img
									src="https://cdn.chiroscript.ai/logo/logo.svg?v=8108891128"
									className="w-full h-[36px]"
								/>
							</div>
							<div className="w-[20%]"></div>
						</div>
					</div>
					<div className={twMerge("w-full !h-full flex flex-col justify-center items-center gap-y-4", clsx({
						hidden: !loading,
					}))}>
						<Loading classes={"w-[4rem] text-primary"} />
					</div>
					<div className={twMerge("w-full h-full flex flex-col gap-4 mt-4 justify-center", clsx({
						"hidden": loading,
					}))}>
						<h5 className="font-inter font-semibold mt-4 text-center mb-2">Clinic Details</h5>
						<div className="w-full flex flex-col gap-6">
							<div className="flex flex-col lg:flex-row w-full gap-8">

							<div className="flex flex-col gap-2 w-full">
									<label
										className="text-font-200 text-[95%] font-inter"
										htmlFor="clinic"
									>
										Clinic Name *
									</label>
									<input
										className={twMerge(
											INPUT_CLASS,
										)}
										ref={clinicName}
										name="clinic"
										id="clinic"
										placeholder="Clinic Name"
									/>
								</div>
							</div>
							<div className="flex flex-col lg:flex-row w-full gap-8">
							<div className="flex flex-col gap-2 w-full">
									<label
										className="text-font-200 text-[95%] font-inter"
										htmlFor="regis"
									>
										Registration Number *
									</label>
									<input
										className={twMerge(
											INPUT_CLASS,
										)}
										ref={regis}
										name="regis"
										id="regis"
										placeholder="Registration Number"
									/>
								</div>
								
								<div className="flex flex-col gap-2 w-full *:cursor-pointer">
									<label htmlFor="country" className="font-inter text-font-200 text-[90%]">
										Country of practice *
									</label>
									<select
										ref={country}
										className={twMerge(
											INPUT_CLASS,
											"*:font-inter *:bg-secondary read-only:text-font-400 read-only:bg-page",
										)}
										id="country"
									>
										<option value={""} selected={true}>
											Select Country
										</option>
										{
											countryOptions.map((item) => {
												return (
													<option value={item.value}>{item.label}</option>
												)
											})
										}

									</select>
								</div>
							</div>
							<div className="flex flex-col w-full">
								{
									imgSrc && (

										<div className="flex flex-row items-center justify-between">
											<img src={imgSrc} width={"48px"} id="logo-image" className="object-contain max-w-[512px] !aspect-square max-h-[512px] h-full" />
											<span role="button" onClick={() => {
												setImgSrc(null);
												imgBlob.current = null
											}} className={twMerge("text-[1.6rem]", clsx({
												"hidden": imgSrcDisabled,
											}))}>&times;</span>
										</div>
									)
								}

								{!imgSrc &&
									(
										<>

											<button className="rounded-[10px] px-4 py-3 gap-4 flex bg-[#F5F5F6] text-black font-normal text-[1rem] items-center" onClick={() => {
												document.querySelector("#logo")?.click()
											}}>
												<svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M14.75 9.75V12.75C14.75 13.1478 14.592 13.5294 14.3107 13.8107C14.0294 14.092 13.6478 14.25 13.25 14.25H2.75C2.35218 14.25 1.97064 14.092 1.68934 13.8107C1.40804 13.5294 1.25 13.1478 1.25 12.75V9.75M11.75 4.5L8 0.75M8 0.75L4.25 4.5M8 0.75V9.75" stroke="#1E1E1E" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
												</svg>
												{imgSrc === null && (<span>Upload Logo</span>)}

											</button>
											<span className="text-gray-600 font-medium font-mono text-[75%]">Image files only. Maximum of 512px x 512px and under 5 MB <b>(Not Required)</b></span>
											<input onChange={imageChange} type="file" id="logo" name="logo" className="hidden" accept="image/png, image/jpeg" />
										</>
									)
								}

							</div>
							<div className="flex flex-row w-full gap-8">
								<div className="flex flex-col gap-2 w-full">
									<label
										className="text-font-200 select-none text-[95%] font-inter"
										htmlFor="address"
									>
										Clinic Address
									</label>
									<textarea ref={address} id="address" name="address" className={twMerge("w-full resize-y", INPUT_CLASS)} placeholder="Enter your full clinic address"></textarea>
								</div>
							</div>
							<button onClick={submitClinic} className="btn flex flex-row mt-[0.8rem] mb-4 lg:mb-0 mx-auto bg-accent stroke-none outline-none border-none w-full hover:bg-accent/95 focus:bg-opacity-100 text-white hover:text-font-800 text-[1.2rem] font-inter font-bold transition-all duration-500 ease-in-out group justify-center focus:border-primary focus:border-[1px] focus:border-solid disabled:bg-gray-300 disabled:text-gray-400 disabled:hover:bg-gray-300 disabled:hover:text-gray-400 disabled:cursor-not-allowed">
								<span>Submit</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)

}


function Parent({ children }) {

	const currentToast = useRef(null);

	const dobRef = useRef(null);

	const dobFiledFlatpickr = useRef(null);

	useEffect(() => {

		if (dobRef.current) {

			let d = new Date();

			let dd = d.getDate();
			let mm = d.getMonth();
			let yyyy = d.getFullYear();

			let maxDate = new Date(yyyy, mm, (dd - 1));

			const f = flatpickr(dobRef.current, {
				altInput: true,
				altFormat: "F j, Y",
				dateFormat: "Y-m-d",
				maxDate,
				defaultDate: BASE_DATE,
			});

			dobFiledFlatpickr.current = f;

			const selectedDates = f.selectedDates;

			// console.log(selectedDates);

			return () => f.destroy();
		}

		return () => { };

	}, [dobRef]);

	const [dob, setDob] = useState(new Date(1997, 3, 21));

	const {
		register,
		handleSubmit,
		watch,
		setError,
		formState: { errors, isValid, isLoading },
	} = useForm();

	let [email, phno, first, last] = watch([
		"email",
		"phno",
		"first",
		"last",
	]);

	const {
		currentUser: { user },
		location: { navigate },
		set: {
			patient: { visible, dismiss, type: encounterType },
		},
	} = useDashboard();

	const [groupDisabled, setGroupDisabled] = useState(false);

	async function patientFormSubmit() {

		const { phone } = await import("phone");
		const Snackbar = await import("node-snackbar");

		if (typeof first !== "string" || first.trim().length == 0) {
			setError("first", {
				message: "First name is required",
				type: "validate",
			});
			return false;
		}

		if (!(typeof last === "string" && last.trim().length > 0)) {
			setError("last", {
				message: "Last name is required",
				type: "validate",
			});
			return false;
		}

		if (!(typeof email === "string" && email.trim().length > 0)) {
			setError("email", {
				message: "Email Address is required",
				type: "validate",
			});
			return false;
		}

		if (typeof phno === "string" && phno.trim().length >= 1) {
			const r = phone(phno, {
				country: undefined,
				validateMobilePrefix: false,
			});
			if (!r.isValid) {
				setError("phno", {
					message:
						"This is not a valid phone number. Please check again.",
					type: "validate",
				});
				return false;
			}
		}

		setGroupDisabled(true);

		const { isPatient } = await import("../../util/util");

		const exists = await isPatient(email);

		if (exists) {
			Snackbar.show({
				text: `There already exists a patient with the email address ${email}. Do you want to overwrite and continue`,
				textColor: "#fff",
				actionText: "Yes",
				actionTextColor: "green",
				secondButtonTextColor: "#fff",
				secondButtonText: "No",
				showSecondButton: !false,
				showAction: true,
				duration: 0,
				customClass: "z-[150]",
				pos: "top-right",
				onActionClick: async () => {

					Snackbar.close();
					await createPatient(true, encounterType);

				},
				onSecondButtonClick: () => {
					setGroupDisabled(false);
					Snackbar.close();
				},
			});
		} else {
			await createPatient(false, encounterType);
		}
	}


	const createPatient = async (exists = false, type = "note") => {

		const { processOpts, errorOpts, successOpts } = await import("../../util/toast");
		const { getHeaders, getURL, getDisplayType } = await import("../../util/util");

		currentToast.current = toast(`Please wait while we ${!exists ? "create a new" : "update the"} patient record and a new ${getDisplayType(type)}`, {
			...processOpts
		});


		let headers = await getHeaders();

		let URL = getURL("patients");

		let patientId;

		try {
			const goData = {
				email,
				first,
				last,
				dob: {
					dd: dobFiledFlatpickr.current.selectedDates[0].getDate(),
					mm: dobFiledFlatpickr.current.selectedDates[0].getMonth() + 1,
					yyyy: dobFiledFlatpickr.current.selectedDates[0].getFullYear(),
				},
				phno,
			}

			if (!goData['phno']) {
				delete goData?.phno
			}

			const response = await fetch(URL, {
				method: "POST",
				headers: headers,
				body: JSON.stringify(goData),
			});

			if (!response.ok) {
				console.error("There was some error");

				toast.update(currentToast.current, {
					...errorOpts,
					render: "There was some error while creating/modifying the patient record. Please try again or contact support@chiorscipt.ai if this error persists.",
				})

				return false;
			} else {

				const data = await response.json();

				patientId = data['data']['id'];
			}


			if (patientId) {

				headers = await getHeaders();

				URL = getURL("patients", patientId, type);

				const rr = await fetch(URL, {
					headers: headers,
					method: "POST",
				});

				if (rr.ok) {

					toast.dismiss();
					const dd = await rr.json();
					let link = dd['redirect']['url'];

					dismiss();
					navigate(link);


				} else {

					toast.update(currentToast.current, {
						...errorOpts,
						render: "There was some error while creating/modifying the patient record. Please try again or contact support@chiorscipt.ai if this error persists.",
					})

				}

			}



		} catch (err) {
			console.error(err);

			toast.update(currentToast.current, {
				...errorOpts,
				render: "There was some error while creating/modifying the patient record. Please try again or contact support@chiorscipt.ai if this error persists.",
			})
		} finally {
			setGroupDisabled(false);
		}
	};

	// console.log(children);
	return (
		<div className={`flex h-[100vh] w-full flex-row gap-5 bg-page p-0 lg:p-6`}>
			{children}
			<dialog
				className={`modal z-[4] ${visible ? "modal-open" : ""}`}
				id="patient_modal"
			>
				<div className="modal-box bg-white rounded-lg w-[95%] md:w-[80%] lg:w-[50%] max-w-5xl relative min-h-fit">
					<button
						className={twMerge("btn btn-sm btn-circle btn-ghost absolute right-2 top-2", clsx({
							"hidden": groupDisabled
						}))}
						onClick={(e) => groupDisabled ? e.preventDefault() : dismiss()}
					>
						✕
					</button>
					{groupDisabled && <div className="flex flex-col gap-y-4 items-center absolute left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%] w-full">
						<Loading classes={"w-[4rem] text-primary"} />
						<span className="text-[0.8rem] text-primary">Please wait while we processs your request</span>
					</div>}
					<h5 className={twMerge("font-inter", clsx({
						"hidden": groupDisabled,
					}))}>Create a new Patient</h5>
					<form
						onSubmit={handleSubmit(patientFormSubmit)}
						className={twMerge("flex flex-col gap-4 mt-4 w-full", clsx({
							"hidden": groupDisabled
						}))} id="create-patient"
					>
						<div className="flex flex-col gap-2 w-full">
							<label
								className="font-inter text-font-200 text-[90%]"
								htmlFor="firstName"
							>
								First Name *
							</label>
							<input
								className={twMerge(
									INPUT_CLASS,
									clsx({
										error: errors["first"],
									})
								)}
								type="text"
								id="firstName"
								placeholder="First Name"
								{...register("first", {
									required: "First name is required",
								})}
							/>
							{typeof errors["first"] !== "undefined" && (
								<p className="ml-1 text-[80%] leading-[0.9] font-semibold mt-[1.2px] text-red-500">
									{errors["first"].message}
								</p>
							)}
						</div>
						<div className="flex flex-col gap-2 w-full">
							<label
								className="font-inter text-font-200 text-[90%]"
								htmlFor="lastName"
							>
								Last Name *
							</label>
							<input
								className={twMerge(
									INPUT_CLASS,
									clsx({
										error: errors["last"],
									})
								)}
								type="text"
								id="lastName"
								placeholder="Last Name"
								{...register("last", {
									required: "Last name is required",
								})}
							/>
							{typeof errors["last"] !== "undefined" && (
								<p className="ml-1 text-[80%] leading-[0.9] font-semibold mt-[1.2px] text-red-500">
									{errors["last"].message}
								</p>
							)}
						</div>
						<div className="flex flex-col gap-2 w-full relative">
							<label
								className="font-inter text-font-200 text-[90%]"
								htmlFor="dob"
							>
								Date of Birth *
							</label>
							<input
								className={twMerge(
									INPUT_CLASS,
									"focus:border-primary cursor-pointer",
								)}
								type="text"
								id="dob"
								placeholder="Date of Birth"
								ref={dobRef}
							/>
						</div>
						<div className="flex flex-col gap-2 w-full relative">
							<label
								className="font-inter text-font-200 text-[90%]"
								htmlFor="email"
							>
								Email *
							</label>
							<input
								className={twMerge(
									INPUT_CLASS,
									clsx({
										error: errors["email"],
									})
								)}
								type="email"
								id="email"
								placeholder="example@example.com"
								{...register("email", {
									required: "Email Address is required",
								})}
							/>
							{typeof errors["email"] !== "undefined" && (
								<p className="ml-1 text-[80%] leading-[0.9] font-semibold mt-[1.2px] text-red-500">
									{errors["email"].message}
								</p>
							)}
						</div>
						<div className="flex flex-col gap-2 w-full relative">
							<label
								className="font-inter text-font-200 text-[90%]"
								htmlFor="phno"
							>
								Phone
							</label>
							<input
								className={twMerge(
									INPUT_CLASS,
									clsx({
										error: errors["phno"],
									})
								)}
								type="tel"
								id="phno"
								placeholder="+1 102 405 4984"
								{...register("phno")}
							/>
							{typeof errors["phno"] !== "undefined" && (
								<p className="ml-1 text-[80%] leading-[0.9] font-semibold mt-[1.2px] text-red-500">
									{errors["phno"].message}
								</p>
							)}
						</div>
						<button className="rounded-[80px] h-[55px] w-full border-primary border-[1px] border-solid font-inter text-primary bg-white hover:bg-accent/65 duration-500 hover:text-font-600 hover:border-opacity-80 shadow-sm shadow-page hover:shadow-none text-[95%]">
							Save
						</button>
					</form>
				</div>
			</dialog>
		</div>
	);
}
